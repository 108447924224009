
const initialState = {
};
export default function (state = initialState, action: any) {
    switch (action.type) {
        case "THEME_PROVIDER":
            return { ...action.payload }
        default:
            return state;
    }
}
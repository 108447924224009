/**
 * Function to convert a URI to a Blob object
 * @param {string} uri - The URI of the file
 * @returns {Promise} - Returns a promise that resolves with the Blob object
 */
export function uriToBlob(uri: string): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', uri, true);
    xhr.responseType = 'blob';
    // If successful -> return with blob  
    xhr.onload = function () {
      if (xhr.status >= 0) {
        resolve(xhr.response);
      }
      else {
        reject(new Error('Failed to retrieve blob from file uri'));
      }
    };

    // reject on error  
    xhr.onerror = function () {
      reject(new Error('uriToBlob failed'));
    };
    // Set the response type to 'blob' - this means the server's response    
    xhr.send();
  });
};
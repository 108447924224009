import getEnvVars from '../../../environment';
const { API_KEY } = getEnvVars();

const razorpayPay = (Razorpay, input, responseFunction) => {
    // const order = await createOrder(params); //  Create order on your backend

    var options = {
        name: "MeePaisa",
        description: "Order Transaction",
        image: "https://www.meepaisa.com/favicon.ico",
        currency: 'INR',
        key: API_KEY, // Your api key
        amount: String(Number(input.amount) * 100),
        prefill: {
            name: input.user.User_Name,
            email: input.user.Email_Id,
            contact: input.user.Mobile,
        },
        theme: { color: '#27B6CC' },
        handler: function (response) {
            responseFunction(true, response);
        },
        "modal": {
            "ondismiss": function (response) {
                responseFunction(false, response);
            }
        }
    }


    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
        responseFunction(false, response);
    });

    rzp1.open();

};

export default razorpayPay;